@import "styles/core.scss";
.one {
  position: fixed;
  top: calc(var(--size-header-height-desktop) + 32px - 8px); // header height + design offset - google's padding
  right: calc(32px - 8px); // design - google's padding
  z-index: calc(var(--z-index-sticky) + 1); // Render above trail page DesktopNav
  display: none;

  @include page-width-md-up {
    display: block;
  }
}
